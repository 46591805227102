/* eslint-disabled */
export const balanceCols = [
  {
    prop: 'orderCode',
    label: '订单号',
    minWidth: '200',
    formatter(row) {
      return row?.orderDTO?.orderCode || '暂无'
    }
  },
  { prop: 'tradeType', label: '交易类型', minWidth: '200', slotName: 'tradeType' },
  {
    prop: 'tradeTime',
    label: '使用时间',
    minWidth: '200',
    slotName: 'tradeTime',
    sortable: true
  },
  {
    prop: 'tradeAmount',
    label: '使用的额度'
  },
  {
    prop: 'tradeTitle',
    label: '备注',
    minWidth: '200'
  }
]
